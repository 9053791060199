.trailingArrowClass {
  width: 88%;
}
.trailingArrowClass::after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 21px solid transparent;
  border-left: 18px solid #f2ece9;
}

.leadingArrowClass {
  width: 88%;
  margin-left: auto;
}
.leadingArrowClass::before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  right: 100%;
  top: 0;
  border: 21px solid transparent;
  border-right: 18px solid #f2ece9;
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: #fff;
}

.fc-day-today {
  background-color: inherit;
}
