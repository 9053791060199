.toast-discard {
  background: #4a4a4a !important;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  color: #fff !important;
  font-size: 14px;
  width: 172px;
  height: 48px;
  padding: 30px 20px;
  margin: auto;
}

.toast-discard button {
  color: #fff !important;
  opacity: 1;
}

.toast-discard-notes {
  background: #4a4a4a !important;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  color: #fff !important;
  font-size: 14px;
  width: 120px;
  height: 48px;
  padding: 16px;
  margin: auto;
}

.toast-delete-notes {
  background: #4a4a4a !important;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  color: #fff !important;
  font-size: 14px;
  width: 160px;
  height: 48px;
  padding: 16px;
  margin: auto;
}

.toast-discard-notes button {
  color: #fff !important;
  opacity: 1;
}

.toast-delete-notes button {
  color: #fff !important;
  opacity: 1;
}

.toast-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  position: absolute;
  width: fit-content;
  height: 48px;
  background: #00859a !important;
  color: #fff !important;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  margin: auto;
}

.toast-message button {
  color: #fff !important;
  opacity: 1;
}

.toast-message a {
  color: #fff;
}
