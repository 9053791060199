/* general */

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: min-content;
}

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.App {
  text-align: center;
}

/* overrides and keyframes */

.interactable {
  user-select: none;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Linkify div {
  white-space: normal;
}

.Linkify div:empty {
  display: block;
  height: 1em;
}

.Linkify-self a,
.Linkify-other a {
  color: inherit !important;
}

#react-modal-image-img {
  width: initial !important;
  height: initial !important;
  object-fit: contain !important;
  border-radius: 0 !important;
  z-index: 1;
}

body.ReactModal__Body--open.blured {
  overflow: hidden;
  position: relative;
}

body.ReactModal__Body--open.blured #root {
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(7px);
}

.ReactCrop__image {
  max-height: 400px;
  min-height: 150px;
  max-width: 100%;
  min-width: 150px;
  user-select: none;
  -webkit-user-drag: none;
}

.MuiBadge-dot {
  border-radius: 50% !important;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

mark.selected-text-urgent-marker,
mark.selected-template-urgent-marker {
  background: #f6f6f9;
  color: inherit;
  font-weight: 600;
}

mark.unselected-text-urgent-marker {
  background: none;
  border: 1px solid #f6f6f9;
}

mark.selected-template-regular-marker,
mark.selected-template-stat-marker,
mark.selected-text-regular-marker,
mark.selected-text-stat-marker {
  background: #ecad0b;
  color: inherit;
  font-weight: 600;
}

mark.unselected-template-regular-marker,
mark.unselected-template-stat-marker,
mark.unselected-template-urgent-marker,
mark.unselected-text-stat-marker,
mark.unselected-text-regular-marker {
  background: none;
  border: 1px solid #ecad0b;
  color: inherit;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    transform: translateX(20%) rotate(3deg);
  }
  45% {
    transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    transform: translateX(10%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}
